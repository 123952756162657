/* eslint-disable */
import {
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Button,
  useColorModeValue,
  Icon,
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card";
import React, { useMemo, useState } from "react";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { MdEdit, MdUpdate } from "react-icons/md";
import { listPreviredId, generatedSynchronization } from "../services";


export default function DevelopmentTable(props) {
  const { columnsData, tableData, setDetailPrevired, loading, reloadList, listTypeWorkDay } = props;
  const [idDetailPreviredSelected, setIdDetailPreviredSelected] = useState(0);
  const resetParams = {
    "id": "",
    title: "",
    content: "",
    requiredSignedEmployer: false,
    requiredSignedWorker: false,
    typeContractId: "",
  }
  const setDataForModal = async (previred) => {
    setIdDetailPreviredSelected(previred.id)
    try {
      const response = await listPreviredId(previred.id);
      setDetailPrevired(response.result.c_previred_details)
    } catch (error) {
      console.error('Error al obtener los datos:', error);
      setDetailPrevired([])
    }
  };
  const synchronization = async () => {
    try {
      const response = await generatedSynchronization()
      if (response.status) {
        reloadList();
      }
      console.log("**** response", response)
    } catch (error) {
      console.error('Error al obtener los datos:', error);
    }
  };
  
  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 100000000 },

    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    initialState,
  } = tableInstance;
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  const textColorSecondary = useColorModeValue("white", "white");
  const boxBg = useColorModeValue("green.300", "green.100");

  return (
    <Card
      direction='column'
      w='100%'
      px='0px'
      overflowX={{ sm: "scroll", lg: "hidden" }}>
      <Flex px='25px' justify='space-between' mb='20px' align='flex-end'>
        <Text
          color={textColor}
          fontSize='22px'
          fontWeight='700'
          lineHeight='100%'>
          Sincronizar Registros
          <Button
            bg={boxBg}
            fontSize='sm'
            fontWeight='500'
            color={textColorSecondary}
            onClick={() => synchronization()}
            borderRadius='7px'>
            <Icon
              as={MdUpdate}
              color={textColorSecondary}
              me='4px'
            />
          </Button>
        </Text>
      </Flex>

      <Flex px='25px' justify='space-between' mb='20px' align='center'>
        <Text
          color={textColor}
          fontSize='22px'
          fontWeight='700'
          lineHeight='100%'>
          Listado
        </Text>
      </Flex>
      <Table {...getTableProps()} variant='simple' color='gray.500' mb='24px'>
        <Thead>
          {headerGroups.map((headerGroup, index) => (
            <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column, index) => (
                <Th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  pe='10px'
                  key={index}

                  borderColor={borderColor}>
                  <Flex
                    justify='space-between'
                    align='center'
                    style={{
                      display: [
                        'p_type_workday_id',
                        'content'].includes(column.render("Header")) ? 'none' : ''
                    }}
                    fontSize={{ sm: "10px", lg: "12px" }}
                    color='gray.400'>
                    {column.render("Header")}
                  </Flex>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        {
          loading ? (
            <div className="container-spinner">
              Cargando información...
              <div className="spinner"></div>
            </div>

          ) : (<Tbody {...getTableBodyProps()}>
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <Tr {...row.getRowProps()} key={index}>
                  {row.cells.map((cell, index) => {
                    let data = "";
                    if (cell.column.Header === "id") {
                      data = (
                        <Text color={textColor} fontSize='sm' fontWeight='700'>
                          {cell.value}
                        </Text>
                      );
                    }
                    if (cell.column.Header === "Año") {
                      data = (
                        <Text color={textColor} fontSize='sm' fontWeight='700'>
                          {cell.value || 'Sin título'}
                        </Text>
                      );
                    }
                    else if (cell.column.Header === "Mes") {
                      data = (
                        <Text color={textColor} fontSize='sm' fontWeight='700'>
                          {cell.value || 'Sin título'}
                        </Text>
                      );
                    } if (cell.column.Header === "Valor UF") {
                      data = (
                        <Text color={textColor} fontSize='sm' fontWeight='700'>
                          {cell.value || 'Sin título'}
                        </Text>
                      );
                    }
                    else if (cell.column.Header === "UTM") {
                      data = (
                        <Text color={textColor} fontSize='sm' fontWeight='700'>
                          {cell.value || 'Sin título'}
                        </Text>
                      );
                    }
                    else if (cell.column.Header === "Acción") {
                      data = (
                        <Button
                          bg={boxBg}
                          fontSize='sm'
                          fontWeight='500'
                          color={textColorSecondary}
                          onClick={() => {
                            setDataForModal(cell.row.values);
                          }}
                          borderRadius='7px'>
                          <Icon
                            as={MdEdit}
                            color={textColorSecondary}
                            me='4px'
                          />
                        </Button>
                      );
                    }
                    return (
                      <Td
                        {...cell.getCellProps()}
                        key={index}
                        fontSize={{ sm: "14px" }}
                        style={{ background: row.cells[0].value === idDetailPreviredSelected ? '#E2E8F0': 'white'}}
                        minW={{ sm: "150px", md: "200px", lg: "auto" }}
                        borderColor='transparent'>
                        {data}
                      </Td>
                    );
                  })}
                </Tr>
              );
            })}
          </Tbody>)
        }

      </Table>
    </Card>
  );
}
