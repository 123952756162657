export const columnsDataDevelopment = [
  {
    Header: "ID",
    accessor: "id",
  },
  {
    Header: "Fecha",
    accessor: "holidayDate",
  },
  {
    Header: "Descripción",
    accessor: "description",
  },
  {
    Header: "Inalienable",
    accessor: "inalienable",
  },
  {
    Header: "Acción",
    accessor: "accion",
  },
];
