import { Box, SimpleGrid } from "@chakra-ui/react";
import DevelopmentTable from "./components/DevelopmentTable";
import {
  columnsDataDevelopment,
} from "./variables/columnsData";
import React, { useEffect, useState } from "react";
import { listUsers } from "./services";

export default function Settings() {
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [codeSubscriptionPlan, setCodeSubscriptionPlan] = useState(null);
  const [codeSubscriptionPlanExternal, setCodeSubscriptionPlanExternal] = useState(null);
  const [statusSubscriptionPlan, setStatusSubscriptionPlan] = useState("TODOS");

  const loadData = async () => {
    try {
      const response = await listUsers({
        codeSubscriptionPlan,
        codeSubscriptionPlanExternal,
        statusSubscriptionPlan,
      });
      setTableData(response.result);
      setLoading(false)
    } catch (error) {
      console.error('Error al obtener los datos:', error);
      setLoading(false)
    }
  };
  
  useEffect(() => {
    setLoading(true)
    loadData();
  }, []);


  const reloadList = () => {
    setLoading(true)
    loadData();
  };

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        mb='20px'
        columns={{ sm: 1, md: 1 }}
        spacing={{ base: "20px", xl: "20px" }}>
         <DevelopmentTable
          columnsData={columnsDataDevelopment}
          loading={loading}
          tableData={tableData}
          reloadList={reloadList}
          codeSubscriptionPlan={codeSubscriptionPlan}
          setCodeSubscriptionPlan={setCodeSubscriptionPlan}
          codeSubscriptionPlanExternal={codeSubscriptionPlanExternal}
          setCodeSubscriptionPlanExternal={setCodeSubscriptionPlanExternal}
          statusSubscriptionPlan={statusSubscriptionPlan}
          setStatusSubscriptionPlan={setStatusSubscriptionPlan}
        />
      </SimpleGrid>
    </Box>
  );
}
