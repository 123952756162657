export const columnsDataDevelopment = [
  {
    Header: "Código",
    accessor: "code",
  },
  {
    Header: "Descripción",
    accessor: "description",
  },
  {
    Header: "Acción",
    accessor: "accion",
  },
];
