export const columnsDataDevelopment = [
  {
    Header: "id",
    accessor: "id",
  },
  {
    Header: "Título",
    accessor: "title",
  },
  {
    Header: "Requerido firma Empleador",
    accessor: "requiredSignedEmployer",
  },
  {
    Header: "Requerido firma Trabajador",
    accessor: "requiredSignedWorker",
  },
  {
    Header: "Tipo día laboral",
    accessor: "p_type_workday.name",
  },
  {
    Header: "p_type_workday_id",
    accessor: "p_type_workday.id",
    visible: false
  },
  {
    Header: "content",
    accessor: "content",
    visible: false
  },
  {
    Header: "Acción",
    accessor: "accion",
  },
];
