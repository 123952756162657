import AxiosDefault from 'utils/axiosDefault';

export const loginExternal = async (auth) => {
    try {
      const response = await AxiosDefault.post('/api/external/login/login-external', {
        ...auth
      });
      console.log('Respuesta del servidor:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error al hacer la solicitud POST:', error);
      throw error;
    }
};
  
  