import axios from 'utils/axios';

export const createAndUpdateTemplateEventCalendar = async (data) => {
  try {
    let url = '/api/admin/template-event-calendar/createTemplateEventCalendar'

    if (data.id !== '') {
      url = '/api/admin/template-event-calendar/updateTemplateEventCalendar'
    } 
    const response = await axios.post(url, {
      ...data
    });
    console.log('Respuesta del servidor:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error al hacer la solicitud POST:', error);
    throw error;
  }
};

export const listTemplateEventCalendars = async () => {
    try {
      const response = await axios.post('/api/admin/template-event-calendar/getTemplateEventCalendars', );
      console.log('Respuesta del servidor:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error al hacer la solicitud POST:', error);
      throw error;
    }
};
  
  