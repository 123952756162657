export const columnsDataDevelopment = [
  {
    Header: "Código",
    accessor: "code",
  },
  {
    Header: "Descripción",
    accessor: "description",
  },
  {
    Header: "Valor",
    accessor: "value",
  },
  {
    Header: "Acción",
    accessor: "accion",
  },
];
