import React, { useState } from 'react';
import './ModalTemplateContract.css'; // Importa tu archivo CSS con los estilos
import SwitchField from "components/fields/SwitchField";
import { createAndUpdatePlantillaContrato } from 'views/admin/template-contract/services';


const ModalTemplateContract = ({ handleOpenModal, templateContract, reloadList, listTypeWorkDay }) => {
  const [title, setTitle] = useState(templateContract.title);
  const [content, setContent] = useState(templateContract.content);
  const [isRequiredSignedEmployer, setIsRequiredSignedEmployer] = useState(templateContract.requiredSignedEmployer);
  const [isRequiredSignedWorker, setRequiredSignedWorker] = useState(templateContract.requiredSignedWorker);
  const [typeContractId, setTypeContractId] = useState(templateContract.typeContractId);

  const [errors,] = useState({ name: '', description: '' });

  const handleSubmit = async () => {
    try {
      setIsValid(!isValid)
      if (!errors.name && !errors.description) {
        // onConfirm({ name, description });
      }
      const response = await createAndUpdatePlantillaContrato({
        id: templateContract.id || '',
        title,
        content,
        "requiredSignedEmployer": isRequiredSignedEmployer,
        "requiredSignedWorker": isRequiredSignedWorker,
        typeContractId,
      });
      reloadList()
      handleOpenModal()
    } catch (error) {
      console.log("*** error", error)
    }
  };
  const [isValid, setIsValid] = useState(null);

  return (
    <>
      <div className="modal">
        <div className="modal-content">
          <div className="modal-header">
            <h2>{templateContract.id === '' ? 'Crear' : 'Actualizar'} Template Contrato</h2>
          </div>
          <div className="modal-body">
            <div className="input-validation-container">
              <div className='input-content'>
                <label className={`input-label ${true ? 'focused' : ''}`} htmlFor="title">
                  Título
                </label>
                <input
                  type="text"
                  name="title"
                  value={title}
                  onChange={(event) => { setTitle(event.target.value) }}
                  className={`general-input ${isValid === false && 'invalid'}`}
                />
                <div className={`message ${isValid === false && 'invalid'}`}>
                  {isValid === false && 'Verificar información: min 10, max 30'}
                </div>
              </div>
              <div className='input-content'>
                <label className={`input-label ${true ? 'focused' : ''}`} htmlFor="title">
                  Tipo de trabajo
                </label>
                <select value={typeContractId} onChange={(event) => {
                  setTypeContractId(event.target.value)
                }}>
                  <option value="">Seleccione una opción</option>
                  {listTypeWorkDay.map((option) => (
                    <option key={option.id} value={option.id}>
                      {option.name}
                    </option>
                  ))}
                </select>
                <div className={`message ${isValid === false && 'invalid'}`}>
                  {isValid === false && 'Verificar información: min 10, max 30'}
                </div>
              </div>

              <div className='input-content'>
                <label className={`input-label ${true ? 'focused' : ''}`} htmlFor="content">
                  Contenido
                </label>
                <textarea
                  type="text"
                  name="content"
                  value={content}
                  style={{ height: '300px' }}
                  onChange={(event) => { setContent(event.target.value) }}
                  className={`general-input ${isValid === false && 'invalid'}`}
                />
                <div className={`message ${isValid === false && 'invalid'}`}>
                  {isValid === false && 'Verificar información: min 10, max 30'}
                </div>
              </div>
              <div className='input-content'>
                <SwitchField
                  isChecked={isRequiredSignedEmployer}
                  reversed={true}
                  onChange={() => setIsRequiredSignedEmployer(!isRequiredSignedEmployer)}
                  fontSize="sm"
                  mb="20px"
                  id="1"
                  label="Requiere firma de empleador"
                />
              </div>
              <div className='input-content'>
                <SwitchField
                  isChecked={isRequiredSignedWorker}
                  reversed={true}
                  onChange={() => setRequiredSignedWorker(!isRequiredSignedWorker)}
                  fontSize="sm"
                  mb="20px"
                  id="2"
                  label="Requiere firma de trabajador"
                />
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <div className="button-container">
              <button className="cancel-button" onClick={() => { handleOpenModal() }}>
                Cancel
              </button>
              <button className="save-button" onClick={() => { handleSubmit() }}>
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ModalTemplateContract;
