export const columnsDataDevelopment = [
  {
    Header: "id",
    accessor: "id",
  },
  {
    Header: "Año",
    accessor: "year",
  },
  {
    Header: "Mes",
    accessor: "month",
  },
  {
    Header: "Valor UF",
    accessor: "valueUf",
  },
  {
    Header: "UTM",
    accessor: "utm",
  },
  {
    Header: "Acción",
    accessor: "accion",
  },
];
