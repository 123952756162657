export const columnsDataDevelopment = [
  {
    Header: "id",
    accessor: "id",
  },
  {
    Header: "Título",
    accessor: "title",
  },
  {
    Header: "Requerido firma Empleador",
    accessor: "requiredSignedEmployer",
  },
  {
    Header: "Requerido firma Trabajador",
    accessor: "requiredSignedWorker",
  },
  {
    Header: "Evento Calendario",
    accessor: "p_events_calendar.name",
  },
  {
    Header: "Nombre Archivo",
    accessor: "fileName",
  },
  {
    Header: "p_events_calendar_id",
    accessor: "p_events_calendar.id",
    visible: false
  },
  {
    Header: "subjectMail",
    accessor: "subjectMail",
    visible: false
  },
  {
    Header: "contentMail",
    accessor: "contentMail",
    visible: false
  },
  {
    Header: "content",
    accessor: "content",
    visible: false
  },
  {
    Header: "Acción",
    accessor: "accion",
  },
];
