import React, { useState } from 'react';
import './ModalHeadParam.css'; // Importa tu archivo CSS con los estilos
import { createAndUpdateBulkDetailParam } from 'views/admin/params/services';

import {
  Button,
  useColorModeValue,
  Icon,
} from "@chakra-ui/react";
import { MdNoteAdd, MdDelete, MdAdsClick } from "react-icons/md";

const ModalHeadParam = ({ handleOpenModal, HeadParam, reloadList, listTypeWorkDay }) => {
  const [codeHead, setCodeHead] = useState(HeadParam.codeHead);
  const [listParamDetail, setListParamDetail] = useState(HeadParam.listParamDetail);// code:string,value:string,description:string

  const [errors,] = useState({ name: '', description: '' });

  const handleSubmit = async () => {
    try {
      setIsValid(!isValid)
      if (!errors.name && !errors.description) {
        // onConfirm({ name, description });
      }
      const response = await createAndUpdateBulkDetailParam({
        codeHead,
        listParamDetail
      });
      console.log(response)
      reloadList()
      handleOpenModal()
    } catch (error) {
      console.log("*** error", error)
    }
  };
  const textColorSecondary = useColorModeValue("white.600", "white");
  const boxBg = useColorModeValue("green.300", "whiteAlpha.100");
  const boxBgDelete = useColorModeValue("red.300", "whiteAlpha.100");

  const [isValid, setIsValid] = useState(null);
  /*
    NUEVO TABLA PARA CREAR LISTADO 
  */
  // Estados para los campos de entrada de un nuevo item
  const [index, setIndex] = useState('');
  const [newCode, setNewCode] = useState('');
  const [newValue, setNewValue] = useState('');
  const [newDescription, setNewDescription] = useState('');


  // Función para manejar la adición de un nuevo item
  const handleUpdateItem = () => {
    const newItem = { code: newCode, value: newValue, description: newDescription };

    const paramDetail = listParamDetail.map((params,indexParams)=> {
      if (indexParams === index) {
        return {
          ...params,
          ...newItem
        }
      }
      return params
    })
    if (paramDetail) {
      setListParamDetail([...paramDetail]);
    }
    setNewCode('');
    setNewValue('');
    setNewDescription('');
  };

  // Función para manejar la adición de un nuevo item
  const selectedRow = (index) => {
    setIndex(index)
    const paramDetail = listParamDetail.find((params,indexParams)=> indexParams === index)
    if (paramDetail) {
      setNewCode(paramDetail.code);
      setNewValue(paramDetail.value);
      setNewDescription(paramDetail.description);
    } 
  };

  // Función para manejar la eliminación de un item
  const handleDeleteItem = (indexToDelete) => {
    setListParamDetail(listParamDetail.filter((_, index) => index !== indexToDelete));
  };
  return (
    <>
      <div className="modal">
        <div className="modal-content">
          <div className="modal-header">
            <h2>{HeadParam.id === '' ? 'Crear' : 'Actualizar'} Parámetros</h2>
          </div>
          <div className="modal-body">
            <div className="input-validation-container" >
              <div className='input-content'>
                <label className={`input-label ${true ? 'focused' : ''}`} htmlFor="codeHead">
                  Código Cabecera
                </label>
                <input
                  type="text"
                  name="codeHead"
                  disabled={codeHead === '' ? false : true}
                  value={codeHead}
                  onChange={(event) => { setCodeHead(event.target.value) }}
                  className={`general-input ${isValid === false && 'invalid'}`}
                />
                <div className={`message ${isValid === false && 'invalid'}`}>
                  {isValid === false && 'Verificar información: min 10, max 30'}
                </div>
              </div>
              NUEVO REGISTRO
              <div className='input-content'>
                <label className={`input-label ${true ? 'focused' : ''}`}  htmlFor="newCode">
                  Código
                </label>
                <input
                  type="text"
                  name="newCode"
                  disabled
                  value={newCode}
                  onChange={(event) => { setNewCode(event.target.value) }}
                  className={`general-input ${isValid === false && 'invalid'}`}
                />
                <div className={`message ${isValid === false && 'invalid'}`}>
                  {isValid === false && 'Verificar información: min 10, max 30'}
                </div>
              </div>
              <div className='input-content'>
                <label className={`input-label ${true ? 'focused' : ''}`} htmlFor="newValue">
                  Valor
                </label>
                <input
                  type="text"
                  name="newValue"
                  value={newValue}
                  onChange={(event) => { setNewValue(event.target.value) }}
                  className={`general-input ${isValid === false && 'invalid'}`}
                />
                <div className={`message ${isValid === false && 'invalid'}`}>
                  {isValid === false && 'Verificar información: min 10, max 30'}
                </div>
              </div>
              <div className='input-content'>
                <label className={`input-label ${true ? 'focused' : ''}`} htmlFor="newDescription">
                  Descripción
                </label>
                <input
                  type="text"
                  name="newDescription"
                  value={newDescription}
                  onChange={(event) => { setNewDescription(event.target.value) }}
                  className={`general-input ${isValid === false && 'invalid'}`}
                />
                <div className={`message ${isValid === false && 'invalid'}`}>
                  {isValid === false && 'Verificar información: min 10, max 30'}
                </div>
              </div>
              <div className='input-content'>
                <Button
                  bg={boxBg}
                  fontSize='sm'
                  fontWeight='500'
                  disabled={newCode === ''}
                  color={textColorSecondary}
                  onClick={()=>handleUpdateItem()}
                  borderRadius='7px'>
                  <Icon
                    as={MdNoteAdd}
                    color={textColorSecondary}
                    me='4px'
                  />
                  Actualizar
                </Button>

              </div>
              <table>
                <thead>
                  <tr>
                    <th></th>
                    <th>Código</th>
                    <th>Valor</th>
                    <th>Descripción</th>
                  </tr>
                </thead>
                <tbody className="item-list">
                  {listParamDetail.map((item, indexList) => (
                    <tr key={indexList}
                      style={{ background: indexList === index ? '#E2E8F0': 'white'}}
                    >
                      <td>
                        <Button
                          bg={boxBg}
                          fontSize='sm'
                          fontWeight='500'
                          color={textColorSecondary}
                          onClick={() => selectedRow(indexList)}
                          borderRadius='7px'>
                        <Icon
                          as={MdAdsClick}
                          color={textColorSecondary}
                          me='4px'
                        />
                      </Button>
                      </td>
                      <td>{item.code}</td>
                      <td>{item.value}</td>
                      <td>{item.description}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="modal-footer">
            <div className="button-container">
              <button className="cancel-button" onClick={() => { handleOpenModal() }}>
                Cancel
              </button>
              <button className="save-button" onClick={() => { handleSubmit() }}>
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ModalHeadParam;
