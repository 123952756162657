import { Box, SimpleGrid } from "@chakra-ui/react";
import DevelopmentTable from "./components/DevelopmentTable";
import {
  columnsDataDevelopment,
} from "./variables/columnsData";
import {
  columnsDataDevelopmentSubPrevired,
} from "./variables/columnsDataSubPrevired";
import { MdSave } from "react-icons/md";

import React, { useEffect, useState } from "react";
import { listPrevired } from "./services";
import DevelopmentTableDetailPrevired from "./components/DevelopmentTableDetailPrevired";
/* eslint-disable */
import {
  Flex,
  Progress,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Button,
  useColorModeValue,
  Icon,
} from "@chakra-ui/react";

export default function Settings() {
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [defaultData, setDefaultData] = useState([]);
  const loadData = async () => {
    try {
      const response = await listPrevired();
      setTableData(response.result);
      setLoading(false)
    } catch (error) {
      console.error('Error al obtener los datos:', error);
      setLoading(false)
    }
  };

  useEffect(() => {
    setLoading(true)
    loadData();
  }, []);


  const reloadList = () => {
    setLoading(true)
    loadData();
  };

  // Params: previredDetail[]
  const setDetailPrevired = (previredDetail) => {
    setDefaultData(previredDetail)
  };
  // Params: previred and previredDetail[]

  const setSubDetail = (previred, previredDetail) => {
    setDefaultData(defaultData.map((data)=>{
      if (previred.id === data.id){
        data = {
          ...data,
          c_previred_subdetails: previredDetail
        }
      }
      return data
    }))
  };
  const updateSubDetail = () => {
    console.log("*** data a actualizar", defaultData)
    const mapperRequest = defaultData.map((data)=>{
      const mapperSubDetail = data.c_previred_subdetails.map((subDetail)=>{
        return {
          type: subDetail.type,
          value: subDetail.value,
        }
      })
      return {
        type: data.type,
        value: data.value,
        c_previred_subdetails: mapperSubDetail
      }
    })
    generatedBulPreviredDetail({
      id: previred.id,
      c_previred_details: mapperRequest
    })
  };

  const textColorSecondary = useColorModeValue("white", "white");
  const boxBg = useColorModeValue("green.300", "green.100");
  const textColor = useColorModeValue("secondaryGray.900", "white");

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        mb='20px'
        columns={{ sm: 2, md: 2 }}
        spacing={{ base: "20px", xl: "20px" }}>
        <DevelopmentTable
          columnsData={columnsDataDevelopment}
          loading={loading}
          tableData={tableData}
          reloadList={reloadList}
          setDetailPrevired={setDetailPrevired}
        />
        <SimpleGrid
          mb='20px'
          columns={{ sm: 1, md: 1 }}
          spacing={{ base: "20px", xl: "20px" }}>
          {
            defaultData.length > 0 && (
              <Flex px='25px' justify='space-between' mb='20px' align='flex-end'>
                <Text
                  color={textColor}
                  fontSize='22px'
                  fontWeight='700'
                  lineHeight='100%'>
                  Actualizar
                  <Button
                    bg={boxBg}
                    fontSize='sm'
                    fontWeight='500'
                    color={textColorSecondary}
                    onClick={() => updateSubDetail()}
                    borderRadius='7px'>
                    <Icon
                      as={MdSave}
                      color={textColorSecondary}
                      me='4px'
                    />
                  </Button>
                </Text>
              </Flex>
            )
          }
          {defaultData.length > 0 && defaultData.map((item, index) => (
            <DevelopmentTableDetailPrevired
              key={index}
              columnsData={columnsDataDevelopmentSubPrevired}
              previred={item}
              previredSubDetail={item.c_previred_subdetails}
              loading={loading}
              reloadList={reloadList}
              setSubDetail={setSubDetail} />
          ))}

        </SimpleGrid>
      </SimpleGrid>
    </Box>
  );
}
