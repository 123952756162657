import axios from 'utils/axios';

export const listTypeEventCalendar = async () => {
    try {
      const response = await axios.post('/api/admin/type-event-calendar/getTypeEventCalendar', );
      console.log('Respuesta del servidor:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error al hacer la solicitud POST:', error);
      throw error;
    }
};
  
  