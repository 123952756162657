import React, { useState } from 'react';
import './ModalMessageApplication.css'; // Importa tu archivo CSS con los estilos
import SwitchField from "components/fields/SwitchField";
import { createAndUpdateMessageApplication } from 'views/admin/message-application/services';

const ModalMessageApplication = ({ handleOpenModal, messageApplication, reloadList }) => {
  
  const [code, setCode] = useState(messageApplication.code);
  const [description, setDescription] = useState(messageApplication.description);
  const [value, setValue] = useState(messageApplication.value);
  const [errors, ] = useState({ name: '', description: '' });
  const [isValid, setIsValid] = useState(null);

  // const validateInput = (inputName, inputValue) => {
  //   let error = '';
  //   if (inputValue.length < 3) error = 'Length is too short';
  //   if (inputValue.length > 50) error = 'Length is too long';
  //   return error;
  // };

  const handleSubmit = async () => {
    try {
      setIsValid(!isValid)
      if (!errors.name && !errors.description) {
        // onConfirm({ name, description });
      }
      const response = await createAndUpdateMessageApplication({
        description,
        code,
        value,
      });
      console.log(response)
      reloadList()
      handleOpenModal()
    } catch (error) {
      console.log("*** error", error)
    }
  };

  return (
    <>
      <div className="modal">
        <div className="modal-content">
          <div className="modal-header">
            <h2>{ messageApplication.id === '' ? 'Crear': 'Actualizar'} Mensaje de Aplicación</h2>
          </div>
          <div className="modal-body">
            <div className="input-validation-container">
              <div className='input-content'>
                <label className={`input-label ${true ? 'focused' : ''}`} htmlFor="code">
                  Código
                </label>
                <input
                  type="text"
                  name="code"
                  value={code}
                  disabled={!(messageApplication.id === '')}
                  onChange={(event)=> { setCode(event.target.value)}}
                  className={`general-input ${isValid === false && 'invalid'}`}
                />
              </div>
              <div className='input-content'>
                <label className={`input-label ${true ? 'focused' : ''}`} htmlFor="description">
                  Descripción
                </label>
                <input
                  type="text"
                  name="description"
                  value={description}
                  onChange={(event)=> { setDescription(event.target.value)}}
                  className={`general-input ${isValid === false && 'invalid'}`}
                />
                <div className={`message ${isValid === false && 'invalid'}`}>
                  {isValid === false && 'Verificar información: min 10, max 30'}
                </div>
              </div>
              <div className='input-content'>
                <label className={`input-label ${true ? 'focused' : ''}`} htmlFor="value">
                  Valor
                </label>
                <input
                  type="text"
                  name="value"
                  value={value}
                  onChange={(event)=> { setValue(event.target.value)}}
                  className={`general-input ${isValid === false && 'invalid'}`}
                />
                <div className={`message ${isValid === false && 'invalid'}`}>
                  {isValid === false && 'Verificar información: min 10, max 30'}
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <div className="button-container">
              <button className="cancel-button" onClick={() => { handleOpenModal() }}>
                Cancel
              </button>
              <button className="save-button" onClick={() => { handleSubmit() }}>
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ModalMessageApplication;
