import { Box, SimpleGrid } from "@chakra-ui/react";
import DevelopmentTable from "./components/DevelopmentTable";
import {
  columnsDataDevelopment,
} from "./variables/columnsData";
import React, { useEffect, useState } from "react";
import { listTemplateEventCalendars } from "./services";
import { listTypeEventCalendar } from "../type-event-calendar/services";

export default function Settings() {
  const [tableData, setTableData] = useState([]);
  const [responseListTypeWorkDay, setResponseListTypeEventCalendar] = useState([]);
  const [loading, setLoading] = useState(false);

  const loadData = async () => {
    try {
      const response = await listTemplateEventCalendars();
      const responseListType = await listTypeEventCalendar();
      setTableData(response.result);
      setResponseListTypeEventCalendar(responseListType.result);
      setLoading(false)
    } catch (error) {
      console.error('Error al obtener los datos:', error);
      setLoading(false)
    }
  };
  
  useEffect(() => {
    setLoading(true)
    loadData();
  }, []);


  const reloadList = () => {
    setLoading(true)
    loadData();
  };

  return (
    <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
      <SimpleGrid
        mb='20px'
        columns={{ sm: 1, md: 1 }}
        spacing={{ base: "20px", xl: "20px" }}>
         <DevelopmentTable
          columnsData={columnsDataDevelopment}
          loading={loading}
          tableData={tableData}
          reloadList={reloadList}
          listTypeEventCalendars={responseListTypeWorkDay}
        />
      </SimpleGrid>
    </Box>
  );
}
