/* eslint-disable */
import {
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card";
import React, { useMemo, useState } from "react";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";

export default function DevelopmentTableDetailPrevired(props) {
  const { columnsData, previred, setSubDetail, previredSubDetail, loading, reloadList, listTypeWorkDay } = props;
  console.log("*** previred", previred)
  const resetParams = {
    "id": "",
    title: "",
    content: "",
    requiredSignedEmployer: false,
    requiredSignedWorker: false,
    typeContractId: "",
  }

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => previredSubDetail, [previredSubDetail]);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 100000000 },

    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    initialState,
  } = tableInstance;

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  const handleInputChange = (index, fieldName, value) => {
    const newRows = [...data];
    newRows[index][fieldName] = value;
    setSubDetail(previred,newRows)
  };

  return (
    <Card
      direction='column'
      w='100%'
      px='0px'
      overflowX={{ sm: "scroll", lg: "hidden" }}>
      <Flex px='25px' justify='space-between' mb='20px' align='center'>
        <Text
          color={textColor}
          fontSize='22px'
          fontWeight='700'
          lineHeight='100%'>
          TIPO: {previred.type}
        </Text>
        <Text
          color={textColor}
          fontSize='22px'
          fontWeight='700'
          lineHeight='100%'>
          VALOR: {previred.value}
        </Text>
      </Flex>
      <Table {...getTableProps()} variant='simple' color='gray.500' mb='24px'>
        <Thead>
          {headerGroups.map((headerGroup, index) => (
            <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column, index) => (
                <Th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  pe='10px'
                  key={index}

                  borderColor={borderColor}>
                  <Flex
                    justify='space-between'
                    align='center'
                    style={{
                      display: [
                        'p_type_workday_id',
                        'content'].includes(column.render("Header")) ? 'none' : ''
                    }}
                    fontSize={{ sm: "10px", lg: "12px" }}
                    color='gray.400'>
                    {column.render("Header")}
                  </Flex>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        {
          loading ? (
            <div className="container-spinner">
              Cargando información...
              <div className="spinner"></div>
            </div>

          ) : (<Tbody {...getTableBodyProps()}>
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <Tr {...row.getRowProps()} key={index}>
                  {row.cells.map((cell, indexTd) => {
                    let data = "";
                    if (cell.column.Header === "id") {
                      data = (
                        <Text color={textColor} fontSize='sm' fontWeight='700'>
                          {cell.value}
                        </Text>
                      );
                    }
                    if (cell.column.Header === "Tipo") {
                      data = (
                          <input
                            type="text"
                            name="id"
                            value={cell.value}
                            className={`general-input`}
                            onChange={(e) => handleInputChange(index, 'id', e.target.value)}
                          />
                      );
                    }
                    else if (cell.column.Header === "Valor") {
                      data = (
                        <input
                          type="text"
                          name="value"
                          value={cell.value}
                          className={`general-input`}
                          onChange={(e) => handleInputChange(index, 'value', e.target.value)}
                        />
                      );
                    }
                    return (
                      <Td
                        {...cell.getCellProps()}
                        key={indexTd}
                        fontSize={{ sm: "14px" }}
                        minW={{ sm: "150px", md: "200px", lg: "auto" }}
                        borderColor='transparent'>
                        {data}
                      </Td>
                    );
                  })}
                </Tr>
              );
            })}
          </Tbody>)
        }

      </Table>
    </Card>
  );
}
