export const columnsDataDevelopmentSubPrevired = [
  {
    Header: "id",
    accessor: "id",
  },
  {
    Header: "Tipo",
    accessor: "type",
  },
  {
    Header: "Valor",
    accessor: "value",
  },
];
