export const columnsDataDevelopment = [
  {
    Header: "ID",
    accessor: "id",
  },
  {
    Header: "Email",
    accessor: "email",
  },
  {
    Header: "Nombre",
    accessor: "name",
  },
  {
    Header: "Apellido",
    accessor: "lastName",
  },
  {
    Header: "Estado de Suscripción",
    accessor: "statusSubscriptionPlan",
  },
  {
    Header: "Código de Suscripción",
    accessor: "codeSubscriptionPlan",
  },
  {
    Header: "Plan",
    accessor: "plan_name",
  },
];
