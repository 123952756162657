/* eslint-disable */
import {
  Flex,
  Progress,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Button,
  useColorModeValue,
  Icon,
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card";
import ModalHoliday from "components/manager/holiday/ModalHoliday";
import React, { useEffect, useMemo, useState } from "react";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { MdNoteAdd, MdEdit } from "react-icons/md";
import { MdCheckCircle, MdCancel } from "react-icons/md";

export default function DevelopmentTable(props) {
  const { columnsData, tableData, loading, reloadList } = props;
  const resetParams = {
    "id": "",
    "holidayDate": "",
    "description": "",
    "inalienable": true
  }
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [params, setParams] = useState(resetParams);

  const handleOpenModal = (estado = "crear") => {
    if (estado === 'crear') {
      setParams(resetParams)
    }
    setIsModalOpen(!isModalOpen)
  };

  const setDataForModal = (holiday) => {
    setParams({
      "id": holiday.id,
      "holidayDate": holiday.holidayDate,
      "description": holiday.description,
      "inalienable": holiday.inalienable
    })
    handleOpenModal('actualizar');
  };

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 100000000 },

    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    initialState,
  } = tableInstance;

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  const textColorSecondary = useColorModeValue("white", "white");
  const boxBg = useColorModeValue("green.300", "green.100");

  return (
    <Card
      direction='column'
      w='100%'
      px='0px'
      overflowX={{ sm: "scroll", lg: "hidden" }}>
      <Flex px='25px' justify='space-between' mb='20px' align='center'>
        <Text
          color={textColor}
          fontSize='22px'
          fontWeight='700'
          lineHeight='100%'>
          Listado
          <Button
            bg={boxBg}
            fontSize='sm'
            fontWeight='500'
            color={textColorSecondary}
            onClick={handleOpenModal}
            borderRadius='7px'>
            <Icon
              as={MdNoteAdd}
              color={textColorSecondary}
              me='4px'
            />
            Crear
          </Button>
          {isModalOpen && (<ModalHoliday handleOpenModal={handleOpenModal} holiday={params} reloadList={reloadList} />)}
        </Text>
      </Flex>
      <Table {...getTableProps()} variant='simple' color='gray.500' mb='24px'>
        <Thead>
          {headerGroups.map((headerGroup, index) => (
            <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers.map((column, index) => (
                <Th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  pe='10px'
                  key={index}
                  borderColor={borderColor}>
                  <Flex
                    justify='space-between'
                    align='center'
                    fontSize={{ sm: "10px", lg: "12px" }}
                    color='gray.400'>
                    {column.render("Header")}
                  </Flex>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        {
          loading ? (
              <div className="container-spinner">
                Cargando información...
                <div className="spinner"></div>
              </div>
          ) : (<Tbody {...getTableBodyProps()}>
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <Tr {...row.getRowProps()} key={index}>
                  {row.cells.map((cell, index) => {
                    let data = "";
                    if (cell.column.Header === "ID") {
                      data = (
                        <Text color={textColor} fontSize='sm' fontWeight='700'>
                          {cell.value}
                        </Text>
                      );
                    }
                    else if (cell.column.Header === "Fecha") {
                      data = (
                        <Text color={textColor} fontSize='sm' fontWeight='700'>
                          {cell.value}
                        </Text>
                      );
                    }
                    else if (cell.column.Header === "Descripción") {
                      data = (
                        <Text color={textColor} fontSize='sm' fontWeight='700'>
                          {cell.value}
                        </Text>
                      );
                    }
                    else if (cell.column.Header === "Inalienable") {
                      data = (
                        <Flex align='center'>
                          <Icon
                            w='24px'
                            h='24px'
                            me='5px'
                            color={
                              cell.value
                                ? "green.500"
                                : "red.500"
                            }
                            as={
                              cell.value
                                ? MdCheckCircle
                                : MdCancel
                            }
                          />
                          <Text color={textColor} fontSize='sm' fontWeight='700'>
                            {cell.value}
                          </Text>
                        </Flex>
                      );

                    }
                    else if (cell.column.Header === "Acción") {
                      data = (
                        <Button
                          bg={boxBg}
                          fontSize='sm'
                          fontWeight='500'
                          color={textColorSecondary}
                          onClick={() => {
                            setDataForModal(cell.row.values);
                          }}
                          borderRadius='7px'>
                          <Icon
                            as={MdEdit}
                            color={textColorSecondary}
                            me='4px'
                          />
                        </Button>
                      );
                    }
                    return (
                      <Td
                        {...cell.getCellProps()}
                        key={index}
                        fontSize={{ sm: "14px" }}
                        minW={{ sm: "150px", md: "200px", lg: "auto" }}
                        borderColor='transparent'>
                        {data}
                      </Td>
                    );
                  })}
                </Tr>
              );
            })}
          </Tbody>)
        }
      </Table>
    </Card>
  );
}
