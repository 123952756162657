
import React, {useState} from "react";
import {
  Box,
  Flex,
  Button,
  FormControl,
  FormLabel,
  Heading,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import DefaultAuth from "layouts/auth/Default";
import { useHistory } from 'react-router-dom';

// Assets
import illustration from "assets/img/auth/auth.png";
import { MdOutlineRemoveRedEye } from "react-icons/md";
import { RiEyeCloseLine } from "react-icons/ri";
import { loginExternal } from "./services";
import ModalGeneral from "./../../../components/manager/modal-general/ModalGeneral";

function SignIn() {
  // Chakra color mode
  const history = useHistory();
  const textColor = useColorModeValue("navy.700", "white");
  const textColorSecondary = "gray.400";
  const brandStars = useColorModeValue("brand.500", "brand.400");
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleOpenModal = () => {
    setIsModalOpen(!isModalOpen)
  };


  const handleClickLogin = async () => {
    setLoading(true)
    try {
      const response = await loginExternal({
        email,
        "pass": password
      })
      if (!response.status) {
        console.log("*** error", response.error);
        return
      }
      console.log(response.result);
      localStorage.setItem('token', response.result.token);
      setLoading(false)
      history.replace('/admin/previred');
    } catch (error) {
      setIsModalOpen(true);
      setIsModalOpen(true);
      console.error('Error al obtener los datos:', error);
      setLoading(false)
    }

  }

  return (
    <DefaultAuth illustrationBackground={illustration} image={illustration}>
      { isModalOpen && (<ModalGeneral handleOpenModal={handleOpenModal} title={'Error, verificar credenciales / usuario'}></ModalGeneral>)}
      <Flex
        maxW={{ base: "100%", md: "max-content" }}
        w='100%'
        mx={{ base: "auto", lg: "0px" }}
        me='auto'
        h='100%'
        alignItems='start'
        justifyContent='center'
        mb={{ base: "30px", md: "60px" }}
        px={{ base: "25px", md: "0px" }}
        mt={{ base: "40px", md: "14vh" }}
        flexDirection='column'>
        <Box me='auto'  style={{width: '100%', marginRight: 0}}>
          <Heading color={textColor} fontSize='36px' mb='10px' textAlign={'center'} marginRight={0} style={{width: '100%', marginRight: 0}}>
            <svg width="50" height="50" viewBox="0 0 1024 1024" fill="none" xmlns="http://www.w3.org/2000/svg" style={{width: '100%'}}>
              <g clip-path="url(#clip0_4182_14180)">
                <rect width="1024" height="1024" fill="white" />
                <rect width="1024" height="1024" fill="#05E9D1" />
                <path d="M812 467.376H813.416L812.314 466.487L522.431 232.611L522.128 232.366L521.816 232.6L210.7 466.476L209.503 467.376H211H291.626H291.794L291.928 467.274L522.102 292.416L731.669 467.26L731.809 467.376H731.99H812Z" fill="white" stroke="white" />
                <path d="M695.052 500.047H643.046L522.107 399.419L389.782 500.047H336.853L522.107 360.644L695.052 500.047Z" fill="white" />
                <path d="M731.479 466.879L731.482 467.376H731.979H811.989H812.489V466.876V316.703V315.792L811.72 316.282L731.095 367.673L730.862 367.821L730.863 368.098L731.479 466.879Z" fill="white" stroke="white" />
                <path d="M210.998 466.377L210.5 466.379V466.877V790.611V791.111H211H291.626H292.126V790.611V466.569V466.067L291.624 466.069L210.998 466.377Z" fill="white" stroke="white" />
                <path d="M812.489 466.875V466.375H811.989H731.363H730.863V466.875V790.917V791.417H731.363H811.989H812.489V790.917V466.875Z" fill="white" stroke="white" />
              </g>
              <defs>
                <clipPath id="clip0_4182_14180">
                  <rect width="1024" height="1024" fill="white" />
                </clipPath>
              </defs>
            </svg>
            <Text
            paddingTop={3}
            width={'100%'}
              color={
                textColor
              }
              fontWeight={
                "bold"
              }>
              DOMESTIKCO
            </Text>

          </Heading>
        </Box>
        <Flex
          zIndex='2'
          direction='column'
          w={{ base: "100%", md: "420px" }}
          maxW='100%'
          background='transparent'
          borderRadius='15px'
          mx={{ base: "auto", lg: "unset" }}
          me='auto'
          mb={{ base: "20px", md: "auto" }}>
          <FormControl>
            <FormLabel
              display='flex'
              ms='4px'
              fontSize='sm'
              fontWeight='500'
              color={textColor}
              mb='8px'>
              Email<Text color={brandStars}>*</Text>
            </FormLabel>
            <Input
              isRequired={true}
              variant='auth'
              fontSize='sm'
              ms={{ base: "0px", md: "0px" }}
              type='email'
              placeholder='mail@simmmple.com'
              mb='24px'
              fontWeight='500'
              size='lg'
              onChange={(e) => setEmail(e.target.value)}
            />
            <FormLabel
              ms='4px'
              fontSize='sm'
              fontWeight='500'
              color={textColor}
              display='flex'>
              Contraseña<Text color={brandStars}>*</Text>
            </FormLabel>
            <InputGroup size='md'>
              <Input
                isRequired={true}
                fontSize='sm'
                placeholder='Min. 8 characters'
                mb='24px'
                size='lg'
                type={show ? "text" : "password"}
                variant='auth'
                onChange={(e) => setPassword( e.target.value)}
              />
              <InputRightElement display='flex' alignItems='center' mt='4px'>
                <Icon
                  color={textColorSecondary}
                  _hover={{ cursor: "pointer" }}
                  as={show ? RiEyeCloseLine : MdOutlineRemoveRedEye}
                  onClick={handleClick}
                />
              </InputRightElement>
            </InputGroup>
            <Button
              onClick={handleClickLogin}
              fontSize='sm'
              variant='brand'
              background={'#68D391'}
              fontWeight='500'
              w='100%'
              h='50'
              disabled={loading}
              mb='24px'>
              Ingresar
            </Button>
          </FormControl>
        </Flex>
      </Flex>
    </DefaultAuth>
  );
}

export default SignIn;
