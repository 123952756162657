import React, { useState } from 'react';
import './ModalHoliday.css'; // Importa tu archivo CSS con los estilos
import SwitchField from "components/fields/SwitchField";
import { createAndUpdateHoliday } from 'views/admin/holidays/services';
function formatDate(dateString) {
    var date = new Date(dateString);
    var day = date.getUTCDate();
    var month = date.getUTCMonth() + 1; // Los meses en JavaScript son de 0 a 11
    var year = date.getUTCFullYear();
    day = (day < 10) ? '0' + day : day;
    month = (month < 10) ? '0' + month : month;
    return year + '-' + month + '-' + day;
}

function getDateNowformatDate() {
  var date = new Date();
  var day = date.getUTCDate();
  var month = date.getUTCMonth() + 1; // Los meses en JavaScript son de 0 a 11
  var year = date.getUTCFullYear();
  day = (day < 10) ? '0' + day : day;
  month = (month < 10) ? '0' + month : month;
  return year + '-' + month + '-' + day;
}

const ModalHoliday = ({ handleOpenModal, holiday, reloadList }) => {
  const [description, setDescription] = useState(holiday.description);
  const [isInalienable, setIsInalienable] = useState(holiday.inalienable);
  const [date, setDate] = useState(holiday.holidayDate !== '' ? formatDate(holiday.holidayDate) : getDateNowformatDate());

  const [, setDateFocused] = useState(false);
  const handleDateFocus = () => setDateFocused(true);
  const handleDateBlur = () => setDateFocused(date !== '');

  const [errors, ] = useState({ name: '', description: '' });

  // const validateInput = (inputName, inputValue) => {
  //   let error = '';
  //   if (inputValue.length < 3) error = 'Length is too short';
  //   if (inputValue.length > 50) error = 'Length is too long';
  //   return error;
  // };

  const handleSubmit = async () => {
    try {
      setIsValid(!isValid)
      console.log("*** isInalienable", isInalienable)
      if (!errors.name && !errors.description) {
        // onConfirm({ name, description });
      }
      const response = await createAndUpdateHoliday({
        id: holiday.id || '',
        description,
        inalienable: isInalienable,
        holidayDate: date,
      });
      console.log(response)
      reloadList()
      handleOpenModal()
    } catch (error) {
      console.log("*** error", error)
    }
  };
  const [isValid, setIsValid] = useState(null);

  const handlePasswordChange = (event) => {
    setDescription(event.target.value);
  };

  return (
    <>
      <div className="modal">
        <div className="modal-content">
          <div className="modal-header">
            <h2>{ holiday.id === '' ? 'Crear': 'Actualizar'} Feríado</h2>
          </div>
          <div className="modal-body">
            <div className="input-validation-container">
              <div className='input-content'>
                <label className={`input-label ${true ? 'focused' : ''}`} htmlFor="description">
                  Descripción
                </label>
                <input
                  type="text"
                  name="description"
                  value={description}
                  onChange={handlePasswordChange}
                  className={`general-input ${isValid === false && 'invalid'}`}
                />
                <div className={`message ${isValid === false && 'invalid'}`}>
                  {isValid === false && 'Verificar información: min 10, max 30'}
                </div>
              </div>
              <div className='input-content'>
                <label className={`input-label ${true ? 'focused' : ''}`} htmlFor="date">
                  Día de feriado (dia / mes / año)
                </label> 
                {
                  date !== '' && (
                  <input
                    id="date"
                    type="date"
                    value={date}
                    onChange={(e) => setDate(e.target.value)}
                    onFocus={handleDateFocus}
                    onBlur={handleDateBlur}
                    className={`input-field date-input ${true ? 'focused' : ''}`}
                  />)
                }
                
              </div>
              <div className='input-content'>
                <SwitchField
                  isChecked={isInalienable}
                  reversed={true}
                  onChange={() => setIsInalienable(!isInalienable)}
                  fontSize="sm"
                  mb="20px"
                  id="1"
                  label="Inalienable"
                />
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <div className="button-container">
              <button className="cancel-button" onClick={() => { handleOpenModal() }}>
                Cancel
              </button>
              <button className="save-button" onClick={() => { handleSubmit() }}>
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ModalHoliday;
