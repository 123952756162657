import axios from 'utils/axios';


export const listUsers = async (params) => {
    try {
      const response = await axios.post('/api/admin/users/getUsers', {
        params: {
          statusSubscriptionPlan: params.statusSubscriptionPlan === 'TODOS' ? null : params.statusSubscriptionPlan,
          codeSubscriptionPlan: params.codeSubscriptionPlan === '' ? null : params.codeSubscriptionPlan,
          codeSubscriptionPlanExternal: params.codeSubscriptionPlanExternal === '' ? null : params.codeSubscriptionPlanExternal,
        }
      });
      console.log('Respuesta del servidor:', response.data);
      return response.data;
    } catch (error) {
      console.error('Error al hacer la solicitud POST:', error);
      throw error;
    }
};
  
  
export const bulkNotificationPush = async ({listIdUsers, title, body}) => {
  try {
    const response = await axios.post('/api/admin/users/bulk-notification-push', {
      listIdUsers, title, body
    });
    console.log('Respuesta del servidor:', response.data);
    return response.data;
  } catch (error) {
    console.error('Error al hacer la solicitud POST:', error);
    throw error;
  }
};

