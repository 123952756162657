import React, { useState } from 'react';
import './ModalTemplateGeneral.css'; // Importa tu archivo CSS con los estilos
import SwitchField from "components/fields/SwitchField";
import { createAndUpdateTemplateGeneral } from 'views/admin/template-general/services';


const ModalTemplateGeneral = ({ handleOpenModal, templateGeneral, reloadList }) => {
  const [code, setCode] = useState(templateGeneral.code);
  const [title, setTitle] = useState(templateGeneral.title);
  const [content, setContent] = useState(templateGeneral.content);
  const [subjectMail, setSubjectMail] = useState(templateGeneral.subjectMail);
  const [contentMail, setContentMail] = useState(templateGeneral.contentMail);
  const [fileName, setFileName] = useState(templateGeneral.fileName);
  const [isRequiredSignedEmployer, setIsRequiredSignedEmployer] = useState(templateGeneral.requiredSignedEmployer);
  const [isRequiredSignedWorker, setRequiredSignedWorker] = useState(templateGeneral.requiredSignedWorker);

  const [errors,] = useState({ name: '', description: '' });

  const handleSubmit = async () => {
    try {
      setIsValid(!isValid)
      if (!errors.name && !errors.description) {
        // onConfirm({ name, description });
      }
      const response = await createAndUpdateTemplateGeneral({
        id: templateGeneral.id || '',
        code,
        title,
        content,
        subjectMail,
        contentMail,
        requiredSignedEmployer: isRequiredSignedEmployer,
        requiredSignedWorker: isRequiredSignedWorker,
        fileName,
      });
      console.log(response)
      reloadList()
      handleOpenModal()
    } catch (error) {
      console.log("*** error", error)
    }
  };
  const [isValid, setIsValid] = useState(null);

  return (
    <>
      <div className="modal">
        <div className="modal-content">
          <div className="modal-header">
            <h2>{templateGeneral.id === '' ? 'Crear' : 'Actualizar'} Template Contrato</h2>
          </div>
          <div className="modal-body">
            <div className="input-validation-container">
              <div className='input-content'>
                <label className={`input-label ${true ? 'focused' : ''}`} htmlFor="code">
                  Código
                </label>
                <input
                  type="text"
                  name="code"
                  value={code}
                  disabled={
                    templateGeneral.id !== ''
                  }
                  onChange={(event) => { setCode(event.target.value) }}
                  className={`general-input ${isValid === false && 'invalid'}`}
                />
                <div className={`message ${isValid === false && 'invalid'}`}>
                  {isValid === false && 'Verificar información: min 10, max 30'}
                </div>
              </div>
              <div className='input-content'>
                <label className={`input-label ${true ? 'focused' : ''}`} htmlFor="title">
                  Título
                </label>
                <input
                  type="text"
                  name="title"
                  value={title}
                  onChange={(event) => { setTitle(event.target.value) }}
                  className={`general-input ${isValid === false && 'invalid'}`}
                />
                <div className={`message ${isValid === false && 'invalid'}`}>
                  {isValid === false && 'Verificar información: min 10, max 30'}
                </div>
              </div>

              <div className='input-content'>
                <label className={`input-label ${true ? 'focused' : ''}`} htmlFor="subjectMail">
                  Asunto Correo
                </label>
                <input
                  type="text"
                  name="subjectMail"
                  value={subjectMail}
                  onChange={(event) => { setSubjectMail(event.target.value) }}
                  className={`general-input ${isValid === false && 'invalid'}`}
                />
                <div className={`message ${isValid === false && 'invalid'}`}>
                  {isValid === false && 'Verificar información: min 10, max 30'}
                </div>
              </div>
              <div className='input-content'>
                <label className={`input-label ${true ? 'focused' : ''}`} htmlFor="contentMail">
                  Contenido Correo
                </label>
                <input
                  type="text"
                  name="contentMail"
                  value={contentMail}
                  onChange={(event) => { setContentMail(event.target.value) }}
                  className={`general-input ${isValid === false && 'invalid'}`}
                />
                <div className={`message ${isValid === false && 'invalid'}`}>
                  {isValid === false && 'Verificar información: min 10, max 30'}
                </div>
              </div>
              <div className='input-content'>
                <label className={`input-label ${true ? 'focused' : ''}`} htmlFor="fileName">
                  Nombre Archivo
                </label>
                <input
                  type="text"
                  name="fileName"
                  value={fileName}
                  onChange={(event) => { setFileName(event.target.value) }}
                  className={`general-input ${isValid === false && 'invalid'}`}
                />
                <div className={`message ${isValid === false && 'invalid'}`}>
                  {isValid === false && 'Verificar información: min 10, max 30'}
                </div>
              </div>
              <div className='input-content'>
                <label className={`input-label ${true ? 'focused' : ''}`} htmlFor="content">
                  Contenido
                </label>
                <textarea
                  type="text"
                  name="content"
                  value={content}
                  style={{ height: '300px' }}
                  onChange={(event) => { setContent(event.target.value) }}
                  className={`general-input ${isValid === false && 'invalid'}`}
                />
                <div className={`message ${isValid === false && 'invalid'}`}>
                  {isValid === false && 'Verificar información: min 10, max 30'}
                </div>
              </div>
              <div className='input-content'>
                <SwitchField
                  isChecked={isRequiredSignedEmployer}
                  reversed={true}
                  onChange={() => setIsRequiredSignedEmployer(!isRequiredSignedEmployer)}
                  fontSize="sm"
                  mb="20px"
                  id="1"
                  label="Requiere firma de empleador"
                />
              </div>
              <div className='input-content'>
                <SwitchField
                  isChecked={isRequiredSignedWorker}
                  reversed={true}
                  onChange={() => setRequiredSignedWorker(!isRequiredSignedWorker)}
                  fontSize="sm"
                  mb="20px"
                  id="2"
                  label="Requiere firma de trabajador"
                />
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <div className="button-container">
              <button className="cancel-button" onClick={() => { handleOpenModal() }}>
                Cancel
              </button>
              <button className="save-button" onClick={() => { handleSubmit() }}>
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ModalTemplateGeneral;
