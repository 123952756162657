import React, { useState } from 'react';
import './ModalMessagePush.css'; // Importa tu archivo CSS con los estilos
import {
  Flex,
  Button,
  useColorModeValue,

} from "@chakra-ui/react";

const ModalMessagePush = ({ handleOpenModal, title, generatedMessagePush }) => {
  const [message, setMessage] = useState("");
  const [titleMessage, setTitleMessage] = useState("");
  const boxBg = useColorModeValue("green.600", "whiteAlpha.100");
  const textColorSecondary = useColorModeValue("white", "white");
  const boxBgDelete = useColorModeValue("red.600", "whiteAlpha.100");

  return (
    <>
      <div className="modal">
        <div className="modal-content">
          <div className="modal-header">
            <h2>{title} </h2>
          </div>
          <div className="modal-body">
            <div className="input-validation-container">
              <Flex px='25px' justify='space-between' mb='20px' align='center'>
                  <div style={{ display: 'table-column', width: '100%' }}>
                    <label className={`input-label ${true ? 'focused' : ''}`} htmlFor="titleMessage">
                      Título
                    </label>
                    <input
                      type="text"
                      name="titleMessage"
                      value={titleMessage}
                      onChange={(event) => { setTitleMessage(event.target.value) }}
                      className={`general-input`}
                    />
                  </div>
              </Flex>
              <Flex px='25px' justify='space-between' mb='20px' align='center'>
                <div style={{ display: 'table-column', width: '100%' }}>
                  <label className={`input-label ${true ? 'focused' : ''}`} htmlFor="title">
                    Mensaje
                  </label>
                  <input
                    type="text"
                    name="message"
                    value={message}
                    onChange={(event) => { setMessage(event.target.value) }}
                    className={`general-input`}
                  />
                </div>
              </Flex>
            </div>
          </div>
          <div className="modal-footer">
            <div className="button-container">
              <Button
                bg={boxBgDelete}
                fontSize='xl'
                fontWeight='500'
                color={textColorSecondary}
                onClick={() => {
                  handleOpenModal()
                }}
                borderRadius='7px'>
                Cerrar
              </Button>
              <Button
                bg={boxBg}
                fontSize='xl'
                fontWeight='500'
                disabled={message === ''}
                color={textColorSecondary}
                onClick={() => {
                  if (message === '') {
                    return
                  }
                  generatedMessagePush(titleMessage, message)
                }}
                marginLeft={'100px'}
                borderRadius='7px'>
                Enviar
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ModalMessagePush;
