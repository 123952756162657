import React from 'react';
import './ModalGeneral.css'; // Importa tu archivo CSS con los estilos

const ModalGeneral = ({ handleOpenModal, title }) => {

  return (
    <>
      <div className="modal">
        <div className="modal-content">
          <div className="modal-header">
            <h2>{ title } </h2>
          </div>
          <div className="modal-body">
            <div className="input-validation-container">
            </div>
          </div>
          <div className="modal-footer">
            <div className="button-container">
              <button className="cancel-button" onClick={() => { handleOpenModal() }}>
                Cerrar
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ModalGeneral;
