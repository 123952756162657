import React from "react";

// Chakra imports
import { Flex, Text, useColorModeValue } from "@chakra-ui/react";

// Custom components

export function SidebarBrand() {
  let textColor = useColorModeValue("secondaryGray.900", "white");

  return (
    <Flex align='center' direction='column'>
      <svg width="50" height="50" viewBox="0 0 1024 1024" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g clip-path="url(#clip0_4182_14180)">
          <rect width="1024" height="1024" fill="white" />
          <rect width="1024" height="1024" fill="#05E9D1" />
          <path d="M812 467.376H813.416L812.314 466.487L522.431 232.611L522.128 232.366L521.816 232.6L210.7 466.476L209.503 467.376H211H291.626H291.794L291.928 467.274L522.102 292.416L731.669 467.26L731.809 467.376H731.99H812Z" fill="white" stroke="white" />
          <path d="M695.052 500.047H643.046L522.107 399.419L389.782 500.047H336.853L522.107 360.644L695.052 500.047Z" fill="white" />
          <path d="M731.479 466.879L731.482 467.376H731.979H811.989H812.489V466.876V316.703V315.792L811.72 316.282L731.095 367.673L730.862 367.821L730.863 368.098L731.479 466.879Z" fill="white" stroke="white" />
          <path d="M210.998 466.377L210.5 466.379V466.877V790.611V791.111H211H291.626H292.126V790.611V466.569V466.067L291.624 466.069L210.998 466.377Z" fill="white" stroke="white" />
          <path d="M812.489 466.875V466.375H811.989H731.363H730.863V466.875V790.917V791.417H731.363H811.989H812.489V790.917V466.875Z" fill="white" stroke="white" />
        </g>
        <defs>
          <clipPath id="clip0_4182_14180">
            <rect width="1024" height="1024" fill="white" />
          </clipPath>
        </defs>
      </svg>
      <Text
      paddingTop={3}
        color={
          textColor
        }
        fontWeight={
          "bold"
        }>
        DOMESTIKCO
      </Text>
    </Flex>
  );
}

export default SidebarBrand;
