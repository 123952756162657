/* eslint-disable */
import {
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Button,
  useColorModeValue,
  Icon,
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card";
import React, { useMemo, useState } from "react";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
  useRowSelect,
} from "react-table";
import { MdDownload, MdSearch, MdMessage } from "react-icons/md";
import {bulkNotificationPush} from "./../services";
import ModalMessagePush from "./../../../../components/manager/modal-message-push/ModalMessagePush";

export default function DevelopmentTable(props) {
  const { columnsData, tableData, loading, reloadList, codeSubscriptionPlan,
    setCodeSubscriptionPlan,
    codeSubscriptionPlanExternal,
    setCodeSubscriptionPlanExternal,
    statusSubscriptionPlan,
    setStatusSubscriptionPlan, } = props;
  const resetParams = {
    "id": "",
    "holidayDate": "",
    "description": "",
    "inalienable": true
  }
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [params, setParams] = useState(resetParams);
  const [selectedRowIds, setSelectedRowIds] = useState({});
  const listStatusSubscriptionPlan = [
    'TODOS',
    'ACTIVATED',
    'DEACTIVATED',
    'RECOVERY',
    'SUCCEEDED',
    'CANCELLED',
  ]
  const handleOpenModal = () => {
    setIsModalOpen(!isModalOpen)
  };


  const handlerDownloadCSV = () => {
    const data = [
      ['ID', 'EMAIL', 'NOMBRE', 'APELLIDO', 'ESTADO DE SUSCRIPCIÓN', 'CÓDIGO DE SUSCRIPCIÓN', 'PLAN'],
      ...tableData.map((value) => {
        return [value.id,
        value.email,
        value.name,
        value.lastName,
        value.statusSubscriptionPlan,
        value.codeSubscriptionPlan,
        value.plan_name,]
      })
    ];

    // Crear el contenido CSV
    let csvContent = "data:text/csv;charset=utf-8,";

    data.forEach(function (rowArray) {
      let row = rowArray.join(",");
      csvContent += row + "\r\n";
    });

    // Crear un enlace de descarga
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "data.csv");
    document.body.appendChild(link); // Necesario para Firefox
    link.click(); // Simular click en el enlace para descargar el archivo

  };


  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { 
        pageIndex: 0, 
        pageSize: 100000000,
        selectedRowIds: selectedRowIds,
      },
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    rows,
    initialState,
    state: { selectedRowIds: tableSelectedRowIds },
    toggleAllRowsSelected,
    toggleRowSelected,
  } = tableInstance;

  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  const textColorSecondary = useColorModeValue("white", "white");
  const boxBg = useColorModeValue("green.300", "green.100");

  const handleSelectAll = (e) => {
    const isChecked = e.target.checked;
    toggleAllRowsSelected(isChecked);
    setSelectedRowIds(
      isChecked ? Object.fromEntries(rows.map(row => [row.id, true])) : {}
    );
  };

  // Manejador para seleccionar una fila
  const handleSelectRow = (rowId, e) => {
    const isChecked = e.target.checked;
    toggleRowSelected(rowId, isChecked);
    setSelectedRowIds({
      ...selectedRowIds,
      [rowId]: isChecked,
    });
  };

  const handlerMessagePush = () => {
    if ((Object.keys(selectedRowIds).length === 0)){
      return
    }
    handleOpenModal()
    console.log("*** ", selectedRowIds)
  };

  const generatedMessagePush = async (title, message) => {
    if ((Object.keys(selectedRowIds).length === 0)){
      return
    }
    const listIdUsers = tableData.filter((value, indice) => {
        return selectedRowIds[indice] === true;
    }).map((user)=>user.id);
    
    handleOpenModal()
    try {
      const response = await bulkNotificationPush({
        title,
        body: message,
        listIdUsers,
      });
    } catch (error) {
      console.error('Error al obtener los datos:', error);
    }
  };

  return (
    <Card
      direction='column'
      w='100%'
      px='0px'
      overflowX={{ sm: "scroll", lg: "hidden" }}>
      { isModalOpen && (<ModalMessagePush handleOpenModal={handleOpenModal} title={'Servicio de mensajería push másiva'} generatedMessagePush={generatedMessagePush}></ModalMessagePush>)}

      <Flex px='25px' justify='space-between' mb='20px' align='center'>
        <Text
          color={textColor}
          fontSize='22px'
          fontWeight='700'
          lineHeight='100%'>
          Listado
        </Text>
  
      </Flex>

      <Flex px='25px' justify='space-between' mb='20px' align='center'>
        <div style={{ display: 'table-column'}}>
          <label className={`input-label ${true ? 'focused' : ''}`} htmlFor="title">
                Estado de suscripción
            </label>
            <select value={statusSubscriptionPlan} onChange={(event) => {
              setStatusSubscriptionPlan(event.target.value)
            }}>
              <option value="">Seleccione una opción</option>
              {listStatusSubscriptionPlan.map((option, key) => (
                <option key={key} value={option}>
                  {option}
                </option>
              ))}
            </select>
        </div>

        <div className='input-content'>
          <label className={`input-label ${true ? 'focused' : ''}`} htmlFor="title">
            Código de suscripción de Plan 
          </label>
          <input
            type="text"
            name="codeSubscriptionPlan"
            value={codeSubscriptionPlan}
            onChange={(event) => { setCodeSubscriptionPlan(event.target.value) }}
            className={`general-input`}
          />
        </div>

        <div className='input-content'>
          <label className={`input-label ${true ? 'focused' : ''}`} htmlFor="title">
            Código de suscripción de Plan EXTERNO
          </label>
          <input
            type="text"
            name="codeSubscriptionPlanExternal"
            value={codeSubscriptionPlanExternal}
            onChange={(event) => { setCodeSubscriptionPlanExternal(event.target.value) }}
            className={`general-input`}
          />
        </div>
      </Flex>
      <Flex px='25px' justify='space-between' mb='20px' align='center'>
      <Button
          bg={boxBg}
          fontSize='sm'
          fontWeight='500'
          color={textColorSecondary}
          onClick={()=>{
            reloadList(); 
            setSelectedRowIds({})
          }}
          borderRadius='7px'>
          <Icon
            as={MdSearch}
            color={textColorSecondary}
            me='4px'
          />
          Buscar
        </Button>

        <Button
          bg={boxBg}
          fontSize='sm'
          fontWeight='500'
          disabled={(Object.keys(selectedRowIds).length === 0)}
          color={textColorSecondary}
          onClick={handlerMessagePush}
          borderRadius='7px'>
          <Icon
            as={MdMessage}
            color={textColorSecondary}
            me='4px'
          />
           Mensajería masiva
        </Button>

        <Button
          bg={boxBg}
          fontSize='sm'
          fontWeight='500'
          color={textColorSecondary}
          onClick={handlerDownloadCSV}
          borderRadius='7px'>
          <Icon
            as={MdDownload}
            color={textColorSecondary}
            me='4px'
          />
          Descargar CSV
        </Button>
      </Flex>
      <Table {...getTableProps()} variant='simple' color='gray.500' mb='24px'>
        <Thead>
          {headerGroups.map((headerGroup, index) => (
            <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
              <Th>
                {/* Checkbox para seleccionar todas las filas */}
                <input
                  type="checkbox"
                  onChange={handleSelectAll}
                  checked={rows && rows.length > 0 && rows.length === Object.keys(selectedRowIds).length}
                />
              </Th>
              {headerGroup.headers.map((column, index) => (
                <Th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  pe='10px'
                  key={index}
                  borderColor={borderColor}>
                  <Flex
                    justify='space-between'
                    align='center'
                    fontSize={{ sm: "10px", lg: "12px" }}
                    color='gray.400'>
                    {column.render("Header")}
                  </Flex>
                </Th>
              ))}
            </Tr>
          ))}
        </Thead>
        {
          loading ? (
            <div className="container-spinner">
              Cargando información...
              <div className="spinner"></div>
            </div>
          ) : (<Tbody {...getTableBodyProps()}>
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <Tr {...row.getRowProps()} key={index}>
                  <Td>
                    {/* Checkbox para seleccionar una fila */}
                    <input
                      type="checkbox"
                      onChange={e => handleSelectRow(row.id, e)}
                      checked={!!tableSelectedRowIds[row.id]}
                    />
                  </Td>
                  {row.cells.map((cell, index) => {
                    let data = "";
                    if (cell.column.Header === "ID") {
                      data = (
                        <Text color={textColor} fontSize='sm' fontWeight='700'>
                          {cell.value}
                        </Text>
                      );
                    }
                    else if (cell.column.Header === "Email") {
                      data = (
                        <Text color={textColor} fontSize='sm' fontWeight='700'>
                          {cell.value}
                        </Text>
                      );
                    }
                    else if (cell.column.Header === "Nombre") {
                      data = (
                        <Text color={textColor} fontSize='sm' fontWeight='700'>
                          {cell.value}
                        </Text>
                      );
                    }
                    else if (cell.column.Header === "Apellido") {
                      data = (
                        <Text color={textColor} fontSize='sm' fontWeight='700'>
                          {cell.value}
                        </Text>
                      );
                    }
                    else if (cell.column.Header === "Código de Suscripción") {
                      data = (
                        <Text color={textColor} fontSize='sm' fontWeight='700'>
                          {cell.value}
                        </Text>
                      );
                    }
                    else if (cell.column.Header === "Estado de Suscripción") {
                      data = (
                        <Text color={textColor} fontSize='sm' fontWeight='700'>
                          {cell.value}
                        </Text>
                      );
                    }
                    else if (cell.column.Header === "Plan") {
                      data = (
                        <Text color={textColor} fontSize='sm' fontWeight='700'>
                          {cell.value}
                        </Text>
                      );
                    }
                    return (
                      <Td
                        {...cell.getCellProps()}
                        key={index}
                        fontSize={{ sm: "14px" }}
                        minW={{ sm: "150px", md: "200px", lg: "auto" }}
                        borderColor='transparent'>
                        {data}
                      </Td>
                    );
                  })}
                </Tr>
              );
            })}
          </Tbody>)
        }
      </Table>
    </Card>
  );
}
