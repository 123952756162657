export const columnsDataDevelopment = [
  {
    Header: "id",
    accessor: "id",
  },
  {
    Header: "Código",
    accessor: "code",
  },
  {
    Header: "Título",
    accessor: "title",
  },
  {
    Header: "URL",
    accessor: "url",
  },

  {
    Header: "Acción",
    accessor: "accion",
  },
];
