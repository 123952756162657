import React, { useState } from 'react';
import './ModalTutorialVideo.css'; // Importa tu archivo CSS con los estilos
import SwitchField from "components/fields/SwitchField";
import { createAndUpdateTutorialVideo } from 'views/admin/tutorial-video/services';

const ModalTutorialVideo = ({ handleOpenModal, tutorialVideo, reloadList }) => {
  
  const [id, ] = useState(tutorialVideo.id);
  const [code, setCode] = useState(tutorialVideo.code);
  const [url, setUrl] = useState(tutorialVideo.url);
  const [title, setTitle] = useState(tutorialVideo.title);
  const [errors, ] = useState({ name: '', url: '' });
  const [isValid, setIsValid] = useState(null);

  // const validateInput = (inputName, inputValue) => {
  //   let error = '';
  //   if (inputValue.length < 3) error = 'Length is too short';
  //   if (inputValue.length > 50) error = 'Length is too long';
  //   return error;
  // };

  const handleSubmit = async () => {
    try {
      setIsValid(!isValid)
      if (!errors.name && !errors.url) {
        // onConfirm({ name, url });
      }
      const response = await createAndUpdateTutorialVideo({
        id: tutorialVideo.id || '',
        url,
        title,
        code,
      });
      console.log(response)
      reloadList()
      handleOpenModal()
    } catch (error) {
      console.log("*** error", error)
    }
  };

  return (
    <>
      <div className="modal">
        <div className="modal-content">
          <div className="modal-header">
            <h2>{ tutorialVideo.id === '' ? 'Crear': 'Actualizar'} Tutorial Video</h2>
          </div>
          <div className="modal-body">
            <div className="input-validation-container">
              {tutorialVideo.id !== '' && (
              <div className='input-content'>
                <label className={`input-label ${true ? 'focused' : ''}`} htmlFor="id">
                  ID
                </label>
                <input
                  type="text"
                  name="id"
                  value={id}
                  className={`general-input ${isValid === false && 'invalid'}`}
                />
              </div>
              )}

              <div className='input-content'>
                <label className={`input-label ${true ? 'focused' : ''}`} htmlFor="code">
                  Código
                </label>
                <input
                  type="text"
                  name="code"
                  value={code}
                  onChange={(event)=> { setCode(event.target.value)}}
                  className={`general-input ${isValid === false && 'invalid'}`}
                />
              </div>
              <div className='input-content'>
                <label className={`input-label ${true ? 'focused' : ''}`} htmlFor="title">
                  Título
                </label>
                <input
                  type="text"
                  name="title"
                  value={title}
                  onChange={(event)=> { setTitle(event.target.value)}}
                  className={`general-input ${isValid === false && 'invalid'}`}
                />
                <div className={`message ${isValid === false && 'invalid'}`}>
                  {isValid === false && 'Verificar información: min 10, max 30'}
                </div>
              </div>
              <div className='input-content'>
                <label className={`input-label ${true ? 'focused' : ''}`} htmlFor="url">
                  URL
                </label>
                <input
                  type="text"
                  name="url"
                  value={url}
                  onChange={(event)=> { setUrl(event.target.value)}}
                  className={`general-input ${isValid === false && 'invalid'}`}
                />
                <div className={`message ${isValid === false && 'invalid'}`}>
                  {isValid === false && 'Verificar información: min 10, max 30'}
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <div className="button-container">
              <button className="cancel-button" onClick={() => { handleOpenModal() }}>
                Cancel
              </button>
              <button className="save-button" onClick={() => { handleSubmit() }}>
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ModalTutorialVideo;
